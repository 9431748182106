import { ROLES } from "@iso/constants/common.constant";

export const SELECT_ROLES_ADMIN = [
  ROLES.ADMIN,
  ROLES.SUBADMIN,
  ROLES.CHAIN,
  ROLES.STORE,
  ROLES.USER,
];
export const SELECT_ROLES_CHAIN = [ROLES.CHAIN, ROLES.STORE];
export const SELECT_ROLES_STORE = [ROLES.STORE];

export const SELECT_STATUS = ["利用中", "停止"];

export const SELECT_DISABLE_CHAIN = [ROLES.ADMIN, ROLES.USER, ROLES.SUBADMIN, ""];
export const SELECT_DISABLE_STORE = [
  ROLES.ADMIN,
  ROLES.CHAIN,
  ROLES.USER,
  ROLES.SUBADMIN,
  "",
];
export const INPUT_DISABLE_EMAIL = [ROLES.CHAIN, ROLES.STORE];

export const SELECT_CONTRACT_STATUS = ["契約中", "解約", "停止"];

export const SELECT_TASK_PRIORITY_LIST = {
  OPTIONS: ["高", "中", "低"],
  DEFAULT: "中",
};

export const TASK_STATUS_MAPPING_COLOR = {
  未着手: "#F0C3C0",
  作業中: "#ACC6DF",
  担当者確認中: "#ABCFDB",
  施設確認中: "#B8D9D3",
  デザイン部依頼中: "#FCBA03",
  保留: "#A7A7A7",
  処理済: "#C6C8D9",
  施設チェック中: "#F5A6B4",
  完了: "#D7DCAC",
  依頼中: "#AA80FF",
  user: "transparent",
};

export const TASK_STATUS_PROCESSED = "処理済";
export const SELECT_TASK_STATUS_LIST = {
  OPTIONS: [
    "未着手",
    "作業中",
    "担当者確認中",
    "施設確認中",
    "デザイン部依頼中",
    "保留",
    "依頼中",
    "処理済",
    "施設チェック中",
    "完了",
  ],
  OPTIONS_CHAIN_STORE: [
    "完了",
  ],
  OPTIONS_GANTT_CHART: [
    "全て",
    "未着手",
    "作業中",
    "担当者確認中",
    "施設確認中",
    "デザイン部依頼中",
    "保留",
    "依頼中",
    "処理済",
    "施設チェック中",
    "完了",
  ],
  DEFAULT: "未着手",
  TASK_CHAIN_STORE_DEFAULT: "作業中",
  NOT_CLOSED: "完了以外",
  NOT_ON_HOLD: "保留以外",
  NOT_ASIGNEE_DROPDOWN: "全て",
  IN_PROGRESS_OPTIONS: [
    "未着手",
    "作業中",
    "担当者確認中",
    "施設確認中",
    "デザイン部依頼中",
    "保留",
    "依頼中",
    "処理済",
    "施設チェック中",
    "完了"
  ],
};

export const TASK_DETAIL_LOG_TABLE_TYPE = {
  SUMMARY: "summary",
  DETAIL: "detail",
};
export const TASK_DETAIL_COMMENT_TYPE = {
  USER: "USER",
  STORE: "STORE",
};

export const TASK_STOP_WATCH = {
  ACTIONS: {
    STOP: "STOP",
    START: "START",
  },
  TYPES: {
    EXECUTE: "EXECUTE",
    CONFIRM: "CONFIRM",
  },
};

export const SELECT_TEMPLATE = {
  UNIT: ["円", "%"],
  RECEIVE: ["受入不可", "受入可"],
  INCREASE_DECREASE: ["+", "-"],
};

export const SELECT_TEAM = [
  "Regular",
  "Toyoko",
  "Solare",
  "SH",
  "QM",
  "Prince",
  "Candeo",
  "Fujita",
  "AD",
  "CR",
  "H.I.S",
  "Huis Ten Bosch" ,
  "PS",
  "Team A",
  "Team B",
  "Team C",
  "Richmond",
  "RS",
];

export const SELECT_TEAM_ID = {
  REGULAR: 0,
  TOYOKO: 1,
  SOLARE: 2,
  SH: 3,
  QM: 4,
  PRINCE: 5,
  CANDEO: 6,
  FUJITA: 7,
  CR: 8,
  AD: 9,
  H_I_S: 10,
  HUIS_TEN_BOSCH: 11,
  PS: 12,
  TEAM_A: 13,
  TEAM_B: 14,
  TEAM_C: 15,
  RICHMOND: 16,
  RS: 17,
};

export const SELECT_TEAM_TO_CREATE_ACCOUNT = [
  "Regular",
  "Toyoko",
  "Solare",
  "SH",
  "QM",
  "Prince",
  "Candeo",
  "Fujita",
  "Director",
  "AD",
  "CR",
  "H.I.S",
  "Huis Ten Bosch",
  "PS",
  "Team A",
  "Team B",
  "Team C",
  "Richmond",
  "RS",
];

export const SELECT_DISABLE_TEAM = [ROLES.CHAIN, ROLES.STORE, ""];

export const TASK_CONTENT_TYPES = {
  EXECUTE: "EXECUTE",
  CONFIRM: "CONFIRM",
};

export const TEAM_IN_CHARGE = [
  {
    'accountId': 1,
    'displayName': 'AD'
  },
  {
    'accountId': 2,
    'displayName': 'CR'
  },
  {
    'accountId': 3,
    'displayName': 'PS'
  }
];

export const DAY_OF_WEEK = [
  { value: 'monday', name: '月' },
  { value: 'tuesday', name: '火' },
  { value: 'wednesday', name: '水' },
  { value: 'thursday', name: '木' },
  { value: 'friday', name: '金' }
];

export const SELECT_TYPE_AUTO_COPY_TASK = {
  DAY_OF_THE_WEEK: 1,
  DAY: 2,
  END_OF_THE_MONTH: 3,
  BEGINNING_OF_THE_MONTH: 4,
  END_OF_LAST_MONTH_AND_MIDDLE_OF_THIS_MONTH: 5,
  EVERY_DAY: 6
};
